<template>
  <div class="container is-fluid">
    <b-field grouped group-multiline>
      <b-select v-model="perPage" size="is-small">
        <option value="2">2</option>
        <option value="10">10</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="1000">1000</option>
      </b-select>
    </b-field>
    <b-table
      :data="images"
      :loading="isLoading"
      striped
      paginated
      :per-page="perPage"
      checkable
      :checked-rows.sync="checkedRows"
    >
      <b-table-column
        field="id"
        searchable
        sortable
        :label="t('forms.nr')"
        v-slot="props"
        >{{ props.row.id }}</b-table-column
      >
      <b-table-column
        field="title"
        searchable
        sortable
        :label="t('forms.name')"
        v-slot="props"
        >{{ props.row.title }}</b-table-column
      >
      <b-table-column
        field="category"
        searchable
        sortable
        :label="t('forms.category')"
        v-slot="props"
        >{{ props.row.category }}</b-table-column
      >
      <b-table-column
        field="description"
        searchable
        sortable
        :label="t('forms.description')"
        v-slot="props"
        >{{ props.row.description }}</b-table-column
      >
      <b-table-column
        field="tags"
        searchable
        sortable
        :label="t('forms.tags')"
        v-slot="props"
      >
        <b-taglist>
          <b-tag v-for="tag in props.row.tags" :key="tag" size="is-small">
            {{ tag }}
          </b-tag>
        </b-taglist>
      </b-table-column>
    </b-table>
    <hr />
    <div class="buttons is-centered">
      <b-button type="is-success" :loading="isLoading" @click="save">
        {{t('common.save')}}
      </b-button>
    </div>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { Action } from "@/store/config_actions";

export default {
  name: "ClassroomCreatorDeepzooms",
  mixins: [CommonHelpers],
  components: {},

  props: {
    idDeepzooms: { type: Array, required: false },
  },

  data: function () {
    return {
      questions: [],
      perPage: 50,
      checkedRows: [],
      isLoading: false,
    };
  },

  mounted() {
    if (!this.$store.state.deepzoom.config) {
        this.getDeepZoomConfig();
    }else{
        this.addCheckedDeepzooms();
    }
  },

  watch: {
    checkedRows: function (newValue, oldValue) {
      let difference;
      if (newValue.length > oldValue.length) {
        difference = newValue.filter((x) => !oldValue.includes(x));
        this.addDeepzoomToClassroom(difference);
      } else {
        difference = oldValue.filter((x) => !newValue.includes(x));
        this.removeDeepzoomFromClassroom(difference);
      }
    },

    idDeepzooms() {
      if (this.images.length > 0) {
        this.addCheckedDeepzooms();
      }
    },
  },

  computed: {
    deepZoomConfig() {
      return this.$store.state.deepzoom.config;
    },

    images() {
      if (this.deepZoomConfig) {
        return this.deepZoomConfig.images;
      } else return [];
    },
  },

  methods: {
    save() {
      this.$emit("save");
    },

    getDeepZoomConfig() {
        this.isLoading = true;
        this.$store
          .dispatch(Action.DEEPZOOM_CONFIG_GET)
          .then(() => {
            this.addCheckedDeepzooms();
            this.isLoading = false;
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
            this.isLoading = false;
          });
      
    },

    addCheckedDeepzooms() {
      this.checkedRows = []
      if (this.idDeepzooms) {
        this.idDeepzooms.forEach((id) => {
          let deepzoom = this.images.find((s) => s.id === id);
          if (deepzoom) {
            this.checkedRows.push(deepzoom);
          }
        });
      }
    },

    addDeepzoomToClassroom(deepzoomsChecked) {
      let idDeepzooms = deepzoomsChecked.map((q) => q.id);
      this.$emit("addDeepzooms", idDeepzooms);
    },

    removeDeepzoomFromClassroom(deepzoomsChecked) {
      let idDeepzooms = deepzoomsChecked.map((q) => q.id);
      this.$emit("removeDeepzooms", idDeepzooms);
    },

    selectionDeepzoom(deepzoom) {
      this.$emit("selectionDeepzoom", deepzoom);
    }
  },
};
</script>

<style scoped>
li {
  list-style-type: disc;
}
.text-danger {
  color: red;
}
.text-sucess {
  color: green;
}
</style>