var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"card-header skyColor mb-5"},[_c('p',{staticClass:"subtitle card-header-title pl-5 pt-4 pb-4 mb-0"},[_vm._v(" "+_vm._s(_vm.t("componentNames.examCreator"))+" ")]),_c('UserFeedback',{staticClass:"end mr-4 mt-1",attrs:{"target":_vm.t('componentNames.examCreator')}})],1),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('b-field',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"grouped":"","group-multiline":"","label":_vm.t('exam.type'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.t(errors[0])}},_vm._l((_vm.mExamTypes),function(type){return _c('b-radio-button',{key:type.id,attrs:{"native-value":type.id,"type":"is-success"},on:{"input":function($event){_vm.localExam.type = type.id}},model:{value:(_vm.radioButton),callback:function ($$v) {_vm.radioButton=$$v},expression:"radioButton"}},[_c('b-icon',{attrs:{"icon":type.iconName}}),_c('span',[_vm._v(_vm._s(type.name))])],1)}),1)]}}],null,true)})],1),_c('b-field',[_c('ValidationProvider',{attrs:{"rules":"required|max:99"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.t('exam.title'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.t(errors[0], { max: 99 })}},[_c('b-input',{attrs:{"type":"text","required":""},model:{value:(_vm.localExam.title),callback:function ($$v) {_vm.$set(_vm.localExam, "title", $$v)},expression:"localExam.title"}})],1)]}}],null,true)})],1),_c('b-field',[_c('ValidationProvider',{attrs:{"rules":"required|max:940"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.t('exam.description'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.t(errors[0], { max: 940 })}},[_c('b-input',{attrs:{"type":"textarea","required":""},model:{value:(_vm.localExam.description),callback:function ($$v) {_vm.$set(_vm.localExam, "description", $$v)},expression:"localExam.description"}})],1)]}}],null,true)})],1),_c('b-field',[_c('b-field',{attrs:{"grouped":"","label":_vm.t('exam.time'),"type":{ 'is-danger': _vm.messageTime, 'is-success': !_vm.messageTime },"message":_vm.messageTime}},[_c('b-field',{attrs:{"label":"h","label-position":"on-border"}},[_c('b-numberinput',{attrs:{"max":"24","min":"0","controls-alignment":"right","controls-position":"compact"},model:{value:(_vm.timeHour),callback:function ($$v) {_vm.timeHour=$$v},expression:"timeHour"}})],1),_c('b-field',{attrs:{"label":"min","label-position":"on-border"}},[_c('b-numberinput',{attrs:{"max":"59","min":"0","controls-alignment":"right","controls-position":"compact"},model:{value:(_vm.timeMin),callback:function ($$v) {_vm.timeMin=$$v},expression:"timeMin"}})],1)],1)],1),_c('b-field',[_c('b-field',{attrs:{"label":_vm.t('forms.tags')}},[_c('b-taginput',{attrs:{"ellipsis":"","icon":"label","placeholder":_vm.t('common.add'),"aria-close-label":_vm.t('common.remove')},model:{value:(_vm.localExam.tags),callback:function ($$v) {_vm.$set(_vm.localExam, "tags", $$v)},expression:"localExam.tags"}})],1)],1),_c('div',{staticClass:"columns has-centered"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":_vm.t('exam.gates'),"type":{
              'is-danger': _vm.messageGates,
              'is-success': !_vm.messageGates,
            },"message":_vm.messageGates}}),_vm._l((_vm.localExam.gates),function(gate,index){return _c('b-field',{key:index,staticClass:"m-0",attrs:{"grouped":"","group-multiline":""}},[(index < _vm.localExam.gates.length - 1)?_c('b-field',{staticClass:"m-0 p-0"},[_c('ValidationProvider',{attrs:{"rules":"required|between:0,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var valid = ref.valid;
return [_c('b-field',{staticClass:"m-0 pb-0 mb-4",attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.t(errors[0], { min: 0, max: 100 })}},[_c('p',{staticClass:"subtitle pb-0 m-0"},[_vm._v(" "+_vm._s(_vm.t("exam.gatesFrom"))+" "),(gate.requiredPoints != null)?_c('span',[_vm._v(_vm._s(gate.requiredPoints))]):_c('span',[_vm._v("X")]),_vm._v(" % "+_vm._s(_vm.t("exam.gatesFrom"))+" ")]),_c('b-numberinput',{staticClass:"pl-2 pb-0 m-0",attrs:{"controls-position":"compact","controls-alignment":"right","min":gate.requiredPoints,"size":"is-small","max":"100"},model:{value:(_vm.localExam.gates[index + 1].requiredPoints),callback:function ($$v) {_vm.$set(_vm.localExam.gates[index + 1], "requiredPoints", $$v)},expression:"localExam.gates[index + 1].requiredPoints"}})],1)]}}],null,true)})],1):_c('b-field',{staticClass:"m-0 p-0"},[_c('p',{staticClass:"subtitle m-0 p-0"},[_vm._v(" "+_vm._s(_vm.t("exam.gatesFrom"))+" "),(gate.requiredPoints != null)?_c('span',[_vm._v(_vm._s(gate.requiredPoints))]):_c('span',[_vm._v("X")]),_vm._v(" % "+_vm._s(_vm.t("exam.gatesTo"))+" 100 ")])]),_c('b-field',{staticClass:"mt-0 pt-0 pb-4"},[_c('p',{staticClass:"subtitle p-0 m-0"},[_vm._v(" % "+_vm._s(_vm.t("exam.perGrade"))+": "+_vm._s(gate.grade)+" ")])])],1)})],2)]),(_vm.localExam.type === 0 || _vm.localExam.type === 2)?_c('b-field',{attrs:{"expanded":"","type":{
          'is-danger': _vm.messageQuestion,
          'is-success': !_vm.messageQuestion,
        },"message":_vm.messageQuestion}},[_c('b-field',{attrs:{"expanded":""}},[_c('ExamCreatorQuestions',{attrs:{"checkedID":_vm.localExam.questions},on:{"addQuestions":_vm.addQuestion,"removeQuestions":_vm.removeQuestion}})],1)],1):_vm._e(),(_vm.localExam.type === 1 || _vm.localExam.type === 2)?_c('b-field',{staticClass:"pt-2"},[_c('b-collapse',{staticClass:"panel shadow-local",attrs:{"aria-id":"selectDeepZoom","animation":"slide"},model:{value:(_vm.deepzoomIsOpen),callback:function ($$v) {_vm.deepzoomIsOpen=$$v},expression:"deepzoomIsOpen"}},[_c('div',{staticClass:"local-panel",attrs:{"slot":"trigger"},slot:"trigger"},[_c('b-field',{attrs:{"label":_vm.t('exam.deepZooms'),"label-position":"on-border","message":_vm.messageImages,"type":{
                'is-danger': _vm.messageImages != null,
                'is-success': _vm.messageImages == null,
              }}},[_c('b-taginput',{attrs:{"has-counter":false,"required":"","readonly":true,"clos":"","type":"is-primary","expanded":""},model:{value:(_vm.localExam.images),callback:function ($$v) {_vm.$set(_vm.localExam, "images", $$v)},expression:"localExam.images"}}),_c('b-button',{attrs:{"aria-controls":"selectDeepZoom"}},[_vm._v(_vm._s(_vm.t("exam.selectDeepZoom")))])],1)],1),_c('div',{staticClass:"section"},[_c('ClassroomCreatorDeepzooms',{attrs:{"idDeepzooms":_vm.localExam.images},on:{"addDeepzooms":_vm.addDeepzoom,"removeDeepzooms":_vm.removeDeepzoom,"save":function($event){_vm.deepzoomIsOpen = !_vm.deepzoomIsOpen}}})],1)])],1):_vm._e(),(_vm.localExam.type === 1 || _vm.localExam.type === 2)?_c('b-field',{staticClass:"pt-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.t('exam.maxPointForImage'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":_vm.t(errors[0])}},[_c('b-numberinput',{attrs:{"min":"0","controls-alignment":"right","controls-position":"compact"},model:{value:(_vm.localExam.maxPoints),callback:function ($$v) {_vm.$set(_vm.localExam, "maxPoints", $$v)},expression:"localExam.maxPoints"}})],1)]}}],null,true)})],1):_vm._e(),_c('hr'),_c('div',{staticClass:"buttons"},[(_vm.idExam)?_c('b-button',{attrs:{"type":"is-secondary","icon-left":"close","loading":_vm.isLoading},on:{"click":_vm.backExams}},[_vm._v(_vm._s(_vm.t("common.cancel")))]):_vm._e(),(_vm.idExam)?_c('b-button',{attrs:{"icon-left":"delete","loading":_vm.isLoading,"type":"is-danger"},on:{"click":_vm.confirmDelete}},[_vm._v(_vm._s(_vm.t("exam.remove")))]):_vm._e(),(_vm.idExam)?_c('b-button',{attrs:{"type":"is-primary","icon-left":"content-copy","loading":_vm.isLoading},on:{"click":_vm.saveAsExam}},[_vm._v(_vm._s(_vm.t("common.saveAs")))]):_vm._e(),_c('b-button',{attrs:{"type":"is-success","loading":_vm.isLoading,"disabled":invalid,"icon-left":"check"},on:{"click":_vm.saveExam}},[_vm._v(_vm._s(_vm.t("exam.save")))])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }