<template>
  <div class="section">
    <p class="subtitle">Lista dostępnych pytań</p>
    <b-field grouped group-multiline>
      <b-select v-model="perPage" size="is-small">
        <option value="2">2</option>
        <option value="10">10</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="1000">1000</option>
      </b-select>
      <b-button
        :loading="isLoading"
        @click="getQuestions"
        icon-left="refresh"
        size="is-small"
        >{{t('common.refresh')}}</b-button
      >
      <b-message size="is-small" type="is-warning" class="pl-2" v-if="oldQuestions && oldQuestions.length > 0">        
        {{t('exam.oldQuestionsInfo')}}
        <b-button
          :loading="isLoading"
          @click="removeOldQuestions"          
          icon-left="broom"
          size="is-small"
          type="is-danger"
          >{{t('exam.removeOldQuestions')}}</b-button
        ></b-message
      >
    </b-field>
    <b-table
      :data="questions"
      :show-detail-icon="showDetailIcon"
      detailed
      detail-key="id"
      :opened-detailed="defaultOpenedDetails"
      :loading="isLoading"
      striped
      paginated
      :per-page="perPage"
      checkable
      :checked-rows.sync="checkedRows"
    >
      <b-table-column field="type" :label="t('common.type')" v-slot="props">
        <b-tooltip
          :label="mQuestionTypeName(props.row.type)"
          position="is-right"
        >
          <b-icon :icon="mQuestionTypeIcon(props.row.type)"></b-icon>
        </b-tooltip>
      </b-table-column>
      <b-table-column
        field="category"
        :label="t('question.category')"
        v-slot="props"
        searchable
        sortable
        >{{ props.row.category }}</b-table-column
      >
      <b-table-column
        field="title"
        :label="t('question.title')"
        v-slot="props"
        searchable
        sortable
      >
        {{ props.row.title }}
      </b-table-column>
      <b-table-column
        field="text"
        :label="t('question.text')"
        v-slot="props"
        searchable
        sortable
      >
        {{ props.row.text }}
      </b-table-column>
      <b-table-column v-slot="props">
        <div class="buttons">
          <b-tooltip :label="t('common.preview')">
          <b-button
            icon-left="eye"
            type="is-info is-light"
            @click="showQuestion($event, props.row.id)"
          ></b-button>
          </b-tooltip>
        </div>
      </b-table-column>
      <template slot="detail" slot-scope="props">
        <template>
          <p>{{ props.row.text }}</p>
          <ul v-if="props.row.type === 0">
            <li
              :class="{
                'text-sucess': option.isCorrect,
              }"
              v-for="option in props.row.answers"
              :key="option.id"
            >
              {{ option.text }}
            </li>
          </ul>
        </template>
      </template>
      <template slot="bottom-left">
        <b>{{t('common.selected')}}</b>: {{ checkedRows.length }}
      </template>
    </b-table>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { Action } from "../../../store/config_actions";
import TypesHelpers from "@/mixins/types";

export default {
  name: "ExamCreatorQuestions",
  mixins: [CommonHelpers, TypesHelpers],
  components: {},

  props: { checkedID: { type: Array, required: false } },

  data: function () {
    return {
      questions: [],
      perPage: 50,
      showDetailIcon: false,
      defaultOpenedDetails: [0],
      checkedRows: [],
      isLoading: false,
      oldQuestions: [],
    };
  },

  mounted() {
    this.getQuestions();
    this.oldQuestions = [];
  },

  watch: {
    checkedRows: function (newValue, oldValue) {
      let difference;
      if (newValue.length > oldValue.length) {
        difference = newValue.filter((x) => !oldValue.includes(x));
        this.addQuestionToExam(difference);
      } else {
        difference = oldValue.filter((x) => !newValue.includes(x));
        this.removeQuestion(difference);
      }
    },
  },

  computed: {},

  methods: {
    getQuestions() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.QUESTION_GET_ALL)
        .then((payload) => {
          this.questions = payload;
          this.oldQuestions = [];
          if (this.checkedID) {
            this.addCheckedRows();
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    addCheckedRows() {
      this.checkedID.forEach((element) => {
        let questionChecked = this.questions.find((q) => q.id === element);
        if (questionChecked) {
          this.checkedRows.push(questionChecked);
        } else {
          this.oldQuestions.push(element);
        }
      });
    },

    addNewQuestion() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.QUESTION_ADD, this.newQuestion)
        .then((payload) => {
          this.questions.push(payload);
          this.newQuestion = {
            type: 0,
            title: "",
            text: "",
            answers: [],
            description: "",
            image: "",
            version: "",
          };
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    addQuestionToExam(newQuestions) {
      let idQuestions = newQuestions.map((q) => q.id);
      this.$emit("addQuestions", idQuestions);
    },

    removeQuestion(newQuestions) {
      let idQuestions = newQuestions.map((q) => q.id);
      this.$emit("removeQuestions", idQuestions);
    },

    showQuestion(event, id) {
      let containsId = this.defaultOpenedDetails.includes(id);
      if (containsId) {
        let index = this.defaultOpenedDetails.indexOf(id);
        this.defaultOpenedDetails.splice(index, 1);
      } else {
        this.defaultOpenedDetails.push(id);
      }
      this.modifiedQuestion = false;
    },

    removeOldQuestions() {
      this.oldQuestions.forEach((item) => {
        let index = this.checkedID.indexOf(item);
        if (index > -1) {
          this.checkedID.splice(index, 1);
        }
      });
      this.mSuccessSnack(this.t('exam.oldQuestionsRemoved'))
      this.oldQuestions = []
    },
  },
};
</script>

<style scoped>
li {
  list-style-type: disc;
}
.text-danger {
  color: red;
}
.text-sucess {
  color: #006600;
}
</style>