<template>
  <div>
    <header class="card-header skyColor mb-5">
      <p class="subtitle card-header-title pl-5 pt-4 pb-4 mb-0">
        {{ t("componentNames.examCreator") }}
      </p>
      <UserFeedback
        :target="t('componentNames.examCreator')"
        class="end mr-4 mt-1"
      ></UserFeedback>
    </header>
    <!-- <hr /> -->
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <form>
        <b-field>
          <ValidationProvider rules="required" v-slot="{ errors, valid }">
            <b-field
              grouped
              group-multiline
              :label="t('exam.type')"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="t(errors[0])"
            >
              <b-radio-button
                v-for="type in mExamTypes"
                :key="type.id"
                v-model="radioButton"
                :native-value="type.id"
                type="is-success"
                @input="localExam.type = type.id"
              >
                <b-icon :icon="type.iconName"></b-icon>
                <span>{{ type.name }}</span>
              </b-radio-button>
            </b-field>
          </ValidationProvider>
        </b-field>
        <b-field>
          <ValidationProvider
            rules="required|max:99"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="t('exam.title')"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="t(errors[0], { max: 99 })"
            >
              <b-input type="text" required v-model="localExam.title"></b-input>
            </b-field>
          </ValidationProvider>
        </b-field>
        <b-field>
          <ValidationProvider
            rules="required|max:940"
            v-slot="{ errors, valid }"
          >
            <b-field
              :label="t('exam.description')"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="t(errors[0], { max: 940 })"
            >
              <b-input
                type="textarea"
                required
                v-model="localExam.description"
              ></b-input>
            </b-field>
          </ValidationProvider>
        </b-field>
        <b-field>
          <b-field
            grouped
            :label="t('exam.time')"
            :type="{ 'is-danger': messageTime, 'is-success': !messageTime }"
            :message="messageTime"
          >
            <b-field label="h" label-position="on-border">
              <b-numberinput
                max="24"
                min="0"
                v-model="timeHour"
                controls-alignment="right"
                controls-position="compact"
              ></b-numberinput>
            </b-field>
            <b-field label="min" label-position="on-border">
              <b-numberinput
                max="59"
                min="0"
                v-model="timeMin"
                controls-alignment="right"
                controls-position="compact"
              ></b-numberinput>
            </b-field>
          </b-field>
        </b-field>
        <b-field>
          <b-field :label="t('forms.tags')">
            <b-taginput
              v-model="localExam.tags"
              ellipsis
              icon="label"
              :placeholder="t('common.add')"
              :aria-close-label="t('common.remove')"
            ></b-taginput>
          </b-field>
        </b-field>
        <div class="columns has-centered">
          <div class="column">
            <b-field
              :label="t('exam.gates')"
              :type="{
                'is-danger': messageGates,
                'is-success': !messageGates,
              }"
              :message="messageGates"
            ></b-field>
            <b-field
              v-for="(gate, index) in localExam.gates"
              :key="index"
              grouped
              class="m-0"
              group-multiline
            >
              <b-field
                v-if="index < localExam.gates.length - 1"
                class="m-0 p-0"
              >
                <ValidationProvider
                  rules="required|between:0,100"
                  v-slot="{ errors, valid }"
                >
                  <b-field
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="t(errors[0], { min: 0, max: 100 })"
                    class="m-0 pb-0 mb-4"
                  >
                    <p class="subtitle pb-0 m-0">
                      {{ t("exam.gatesFrom") }}
                      <span v-if="gate.requiredPoints != null">{{
                        gate.requiredPoints
                      }}</span>
                      <span v-else>X</span>
                      % {{ t("exam.gatesFrom") }}
                    </p>
                    <b-numberinput
                      class="pl-2 pb-0 m-0"
                      controls-position="compact"
                      controls-alignment="right"
                      v-model="localExam.gates[index + 1].requiredPoints"
                      :min="gate.requiredPoints"
                      size="is-small"
                      max="100"
                    />
                  </b-field>
                </ValidationProvider>
              </b-field>
              <b-field v-else class="m-0 p-0">
                <p class="subtitle m-0 p-0">
                  {{ t("exam.gatesFrom") }}
                  <span v-if="gate.requiredPoints != null">{{
                    gate.requiredPoints
                  }}</span>
                  <span v-else>X</span>
                  % {{ t("exam.gatesTo") }} 100
                </p>
              </b-field>

              <b-field class="mt-0 pt-0 pb-4">
                <p class="subtitle p-0 m-0">
                  % {{ t("exam.perGrade") }}: {{ gate.grade }}
                </p>
              </b-field>
            </b-field>
          </div>
        </div>
        <b-field
          expanded
          v-if="localExam.type === 0 || localExam.type === 2"
          :type="{
            'is-danger': messageQuestion,
            'is-success': !messageQuestion,
          }"
          :message="messageQuestion"
        >
          <b-field expanded>
            <ExamCreatorQuestions
              @addQuestions="addQuestion"
              @removeQuestions="removeQuestion"
              :checkedID="localExam.questions"
            ></ExamCreatorQuestions>
          </b-field>
        </b-field>
        <b-field
          class="pt-2"
          v-if="localExam.type === 1 || localExam.type === 2"
        >
          <!-- <ValidationProvider rules="required" v-slot="{ errors, valid }"><b-field
              expanded
              label="Wybierz ID zdjęć do egzaminu"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="t(errors[0])"
            >
              <b-select multiple expanded v-model="localExam.images">
                <option
                  v-for="image in images"
                  :key="image.id"
                  :value="image.id"
                >
                  Id:{{ image.id }} Nazwa:{{ image.title }}
                </option>
              </b-select>
            </b-field> 
          </ValidationProvider>-->
          <b-collapse
            aria-id="selectDeepZoom"
            class="panel shadow-local"
            animation="slide"
            v-model="deepzoomIsOpen"
          >
            <div slot="trigger" class="local-panel">
              <b-field
                :label="t('exam.deepZooms')"
                label-position="on-border"
                :message="messageImages"
                :type="{
                  'is-danger': messageImages != null,
                  'is-success': messageImages == null,
                }"
              >
                <b-taginput
                  v-model="localExam.images"
                  :has-counter="false"
                  required
                  :readonly="true"
                  clos
                  type="is-primary"
                  expanded
                ></b-taginput>
                <b-button aria-controls="selectDeepZoom">{{
                  t("exam.selectDeepZoom")
                }}</b-button>
              </b-field>
            </div>
            <div class="section">
              <ClassroomCreatorDeepzooms
                @addDeepzooms="addDeepzoom"
                @removeDeepzooms="removeDeepzoom"
                @save="deepzoomIsOpen = !deepzoomIsOpen"
                :idDeepzooms="localExam.images"
              ></ClassroomCreatorDeepzooms>
            </div>
          </b-collapse>
        </b-field>
        <b-field
          class="pt-2"
          v-if="localExam.type === 1 || localExam.type === 2"
        >
          <ValidationProvider rules="required" v-slot="{ errors, valid }">
            <b-field
              :label="t('exam.maxPointForImage')"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="t(errors[0])"
            >
              <b-numberinput
                min="0"
                v-model="localExam.maxPoints"
                controls-alignment="right"
                controls-position="compact"
              ></b-numberinput>
            </b-field>
          </ValidationProvider>
        </b-field>
        <hr />
        <div class="buttons">
          <b-button
            v-if="idExam"
            @click="backExams"
            type="is-secondary"
            icon-left="close"
            :loading="isLoading"
            >{{ t("common.cancel") }}</b-button
          >
          <b-button
            v-if="idExam"
            icon-left="delete"
            @click="confirmDelete"
            :loading="isLoading"
            type="is-danger"
            >{{ t("exam.remove") }}</b-button
          >
          <b-button
            v-if="idExam"
            @click="saveAsExam"
            type="is-primary"
            icon-left="content-copy"
            :loading="isLoading"
            >{{ t("common.saveAs") }}</b-button
          >
          <b-button
            type="is-success"
            @click="saveExam"
            :loading="isLoading"
            :disabled="invalid"
            icon-left="check"
            >{{ t("exam.save") }}</b-button
          >
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import TypesHelpers from "@/mixins/types";
import ExamCreatorQuestions from "./ExamCreatorQuestions";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Action } from "@/store/config_actions";
import UserFeedback from "@/components/feedbacks/UserFeedback";
import ClassroomCreatorDeepzooms from "@/components/classroom/classroomCreate/ClassroomCreatorDeepzoom";

export default {
  name: "ExamCreator",
  mixins: [CommonHelpers, TypesHelpers],
  components: {
    ExamCreatorQuestions,
    ValidationProvider,
    ValidationObserver,
    UserFeedback,
    ClassroomCreatorDeepzooms,
  },

  props: { idExam: { type: String, required: false } },

  data: function () {
    return {
      messageImages: null,
      deepzoomIsOpen: false,
      isLoading: false,
      localExam: {},
      toSaveExam: {},
      tags: [],
      idQ: 1,
      timeHour: 0,
      timeMin: 0,
      radioButton: "",
      tmpValue: null,
      newExam: {
        title: "",
        description: "",
        type: null,
        questions: [],
        maxTime: 0,
        images: [],
        tags: [],
        gates: [],
        points: 0,
        maxPoints: 0,
      },
      imagePoint: null,
      images: [],
      messageTime: null,
      messageQuestion: null,
      messageGates: null,
      deepZoomConfig: {},
      examGates: [
        {
          grade: 2.0,
          requiredPoints: 0,
        },
        {
          grade: 2.5,
          requiredPoints: null,
        },
        {
          grade: 3.0,
          requiredPoints: null,
        },
        {
          grade: 3.5,
          requiredPoints: null,
        },
        {
          grade: 4.0,
          requiredPoints: null,
        },
        {
          grade: 4.5,
          requiredPoints: null,
        },
        {
          grade: 5.0,
          requiredPoints: null,
        },
      ],
    };
  },

  mounted() {
    //this.getImages();
    this.getDeepZoomConfig();
    if (this.idExam) {
      this.getExam();
    } else {
      this.localExam = JSON.parse(JSON.stringify(this.newExam));
      this.localExam.gates = JSON.parse(JSON.stringify(this.examGates));
    }
  },

  watch: {
    currentImages() {
      if (this.localExam.type === 1 || this.localExam.type === 2) {
        if (this.localExam.images.length === 0) {
          this.messageImages = this.t("exam.messageImageExam");
        } else {
          this.messageImages = null;
        }
      } else {
        this.messageImages = null;
      }
    },
  },

  computed: {
    currentImages() {
      if (this.localExam.type === 1 || this.localExam.type === 2) {
        return this.localExam.images;
      } else return [];
    },
  },

  methods: {
    backExams() {
      this.$emit("close");
    },

    addDeepzoom(idDeepzooms) {
      idDeepzooms.forEach((element) => {
        if (!this.localExam.images.includes(element)) {
          this.localExam.images.push(element);
        }
      });
    },

    removeDeepzoom(idDeepzooms) {
      idDeepzooms.forEach((element) => {
        if (this.localExam.images.includes(element)) {
          this.localExam.images.splice(
            this.localExam.images.indexOf(element),
            1
          );
        }
      });
    },

    getExam() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.EXAM_GET_EXAM_BY_ID, this.idExam)
        .then((payload) => {
          this.localExam = payload;
          this.getHourMinFromMaxTime(this.localExam.maxTime);
          this.radioButton = this.localExam.type;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    getHourMinFromMaxTime(maxTime) {
      var hour = Math.floor(maxTime / 60);
      var min = maxTime % 60;
      this.timeHour = hour;
      this.timeMin = min;
    },

    checkExamToCorrect() {
      var send = false;
      this.localExam.maxTime = this.timeHour * 60 + this.timeMin;
      if (this.localExam.maxTime > 0) {
        send = true;
        this.messageTime = null;
      } else {
        this.messageTime = this.t("exam.messageTime");
        this.mDangerSnack(this.t("exam.messageTime"));
      }

      if (this.localExam.type === 0 || this.localExam.type === 2) {
        if (this.localExam.questions.length > 0) {
          this.messageQuestion = null;
        } else {
          this.messageQuestion = this.t("exam.messageQuestion");
          this.mDangerSnack(this.t("exam.messageQuestion"));
          send = false;
        }
      }

      var falseGate = 0;

      for (let index = 0; index < this.localExam.gates.length - 1; index++) {
        const gate = this.localExam.gates[index];
        const gateNext = this.localExam.gates[index + 1];
        if (gate.requiredPoints >= gateNext.requiredPoints) {
          falseGate++;
        } else {
          //do nothing
        }
      }

      if (falseGate > 0) {
        this.messageGates = this.t("exam.messageGates");
        this.mDangerSnack(this.t("exam.messageGates"));
        send = false;
      } else {
        this.messageGates = null;
      }

      return send;
    },

    saveExam() {
      let send = this.checkExamToCorrect();

      if (send) {
        if (this.idExam) {
          this.updateExam();
        } else {
          this.toSaveExam = this.localExam;
          this.addNewExam(true);
        }
      } else {
        this.mDangerSnack(this.t("common.changesNotSaved"));
      }
    },

    saveAsExam() {
      this.toSaveExam = {
        title: this.localExam.title,
        description: this.localExam.description,
        type: this.localExam.type,
        questions: this.localExam.questions,
        maxTime: this.localExam.maxTime,
        images: this.localExam.images,
        tags: this.localExam.tags,
        gates: this.localExam.gates,
        points: this.localExam.points,
        maxPoints: this.localExam.maxPoints,
      };

      this.addNewExam(false);
    },

    addNewExam(clean) {
      this.isLoading = true;
      this.$store
        .dispatch(Action.EXAM_ADD, this.toSaveExam)
        .then(() => {
          this.mSuccessSnack(this.t("exam.templateSaved"));
          if (clean) {
            this.localExam = {
              title: "",
              description: "",
              type: null,
              questions: [],
              maxTime: 0,
              images: [],
              tags: [],
              gates: [],
            };
            this.localExam.gates = JSON.parse(JSON.stringify(this.examGates));
            this.tags = [];
            this.idQ = 1;
            this.timeHour = 0;
            this.timeMin = 0;
            this.radioButton = "";
            this.tmpValue = null;

            this.$emit("examAdded");
          } else {
            this.$emit("changeSave");
            this.$emit("close");
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    updateExam() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.EXAM_UPDATE, this.localExam)
        .then(() => {
          this.mSuccessSnack(this.t("common.changesSaved"));
          this.isLoading = false;
          this.$emit("changeSave");
          this.$emit("close");
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: this.t("exam.remove"),
        message: this.t("exam.removeConfirm"),
        onConfirm: () => this.deleteExam(),
        type: "is-danger",
        hasIcon: true,
        cancelText: this.t("common.no"),
        confirmText: this.t("common.yes"),
      });
    },

    deleteExam() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.EXAM_REMOVE, this.localExam.id)
        .then(() => {
          this.mSuccessSnack(this.t("exam.removed"));
          this.$emit("deleteExam");
          this.$emit("close");
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    addQuestion(questions) {
      questions.forEach((element) => {
        if (!this.localExam.questions.includes(element))
          this.localExam.questions.push(element);
      });
    },

    removeQuestion(questions) {
      questions.forEach((element) => {
        this.localExam.questions.splice(
          this.localExam.questions.indexOf(element),
          1
        );
      });
    },

    getImages() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.IMAGES_GET_LIST)
        .then((response) => {
          this.isLoading = false;
          this.images = response;
        })
        .catch((error) => {
          this.mDangerSnack(error);
          this.isLoading = false;
        });
    },

    getDeepZoomConfig() {
      this.isLoading = true;
      if (this.$store.state.deepzoom.config) {
        this.deepZoomConfig = this.$store.state.deepzoom.config;
        this.images = this.deepZoomConfig.images;
        this.isLoading = false;
      } else {
        this.$store
          .dispatch(Action.DEEPZOOM_CONFIG_GET)
          .then((payload) => {
            this.deepZoomConfig = payload;
            this.images = payload.images;
            this.isLoading = false;
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
          });
      }
    },
  },
};
</script>

<style scoped>
div.end {
  float: right;
}

.local-panel {
  background-color: #ededed;
  color: #363636;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
}

.shadow-local {
  box-shadow: none !important;
  border-radius: 0px !important;
  border: 1px solid #ededed;
}
</style>
